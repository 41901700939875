// npm
import React, { memo, forwardRef } from 'react'
import { IconButtonProps, styled } from '@mui/material'
// src
import NavArrow from 'images/nav-arrow.svg'
import IconButton from '@atoms/buttons/IconButton'

interface Props extends IconButtonProps {
  direction: 'left' | 'right'
}

const NavButton = styled(IconButton)(({ theme }) => ({
  width: '60px',
  height: '60px',
  zIndex: 10,
  ['&.left']: {
    marginRight: '10px',
    ['svg']: {
      transform: 'rotate(180deg)',
    },
  },
  ['&.swiper-button-disabled']: {
    opacity: '0.4',
  },
  [theme.breakpoints.up('md')]: {
    width: '70px',
    height: '70px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '80px',
    height: '80px',
  },
}))

const Arrow = styled(NavArrow)(({ theme }) => ({
  width: '18px',
  [theme.breakpoints.up('md')]: {
    width: '19px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '20px',
  },
}))

const DirectionButton = forwardRef<HTMLButtonElement, Props>(
  ({ direction, className, ...props }, ref) => {
    return (
      <NavButton ref={ref} className={`${direction} ${className}`} {...props}>
        <Arrow />
      </NavButton>
    )
  }
)

export default memo(DirectionButton)
